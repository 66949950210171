<template>
  <ion-page>
    <div class="background--living" />
    <ion-header
      class="ion-no-border"
    >
      <ion-toolbar
        color="transparent"
      >
        <ion-item
          v-if="selectedView === '1'"
          slot="start"
          lines="none"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="$router.push('/main-view')"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-item
          v-else
          slot="start"
          lines="none"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'; selectedArticle = []"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-title class="text--white text-18">
          {{ $t('helpCenterTitle') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div>
        <div
          v-if="selectedView === '1'"
        >
          <ion-item
            button
            detail="false"
            class="h-55 rounded-corners text--white ion-margin-start ion-margin-end"
            lines="none"
            @click="selectedView = '2'"
          >
            <ion-label
              slot="start"
              class="text--white"
            >
              {{ $t('faq') }}
            </ion-label>
            <ion-icon
              v-if="!loading"
              slot="end"
              size="small"
              color="primary"
              :icon="chevronForwardOutline"
            />
            <ion-spinner
              v-else
              slot="end"
              color="light"
              name="crescent"
            />
          </ion-item>
          <div class="mt-8">
            <div class="text-left ion-padding-start">
              <div
                class="mt-8 text--white"
                style="margin-top: 20px;"
              >
                {{ $t('Manuals') }}
              </div>
            </div>
            <div class="connect-devices">
              <ion-row class="ion-margin-top">
                <ion-col
                  v-for="device in devicesHelpContent"
                  :key="device"
                  size-sm="6"
                  size-xs="6"
                  size-lg="2"
                  size-xl="2"
                  tappable
                >
                  <div
                    class="device rounded-corners"
                    @click="openLink(device.link)"
                  >
                    <div>
                      <div class="device-img">
                        <img :src="require('../assets/devices/' + device.image + '.png')">
                      </div>
                      <h3 class="text--white">
                        {{ device.title }}
                      </h3>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
              <ion-col style="min-heigth: 100px;" />
            </div>
          </div>
        </div>
        <div
          v-else-if="selectedView === '2'"
          class="text-left ion-padding"
        >
          <ion-searchbar
            v-model="search"
            class="search-bar"
            :placeholder="$t('search')"
          />
          <div
            v-for="item in helpContent"
            :key="item"
          >
            <ion-item
              button
              detail="false"
              class="h-55 rounded-corners text--white"
              lines="none"
              @click="selectArticle(item.id); search=''"
            >
              <ion-label slot="start">
                {{ item.Title }}
              </ion-label>
              <ion-icon
                slot="end"
                size="small"
                color="primary"
                :icon="chevronForwardOutline"
              />
            </ion-item>
          </div>
        </div>
        <div
          v-else-if="selectedView === '3'"
          class="text-left white-bg"
          style="height: 100%;"
        >
          <!-- <p class="text--bold">
            {{ selectedArticle[0].Title }}
          </p> -->
          <div class="pt-10">
            <markdown
              :source="selectedArticle[0].Description"
            />
          </div>
        </div>
        <div
          v-else-if="selectedView === '4'"
          class="text-left text--white ion-padding-start ion-padding-end"
          style="height: 100%;"
        >
          <p class="text--bold">
            {{ selectedArticle[0].Title }}
          </p>
          <div class="pt-10">
            <markdown
              :source="selectedArticle[0].Description"
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { chevronForwardOutline } from 'ionicons/icons'
import Markdown from 'vue3-markdown-it'

import { InAppBrowser } from '@ionic-native/in-app-browser/'

export default {
  name: 'HelpCenter',
  components: {
    Markdown
  },
  data () {
    return {
      loading: false,
      search: '',
      chevronForwardOutline,
      selectedView: '1',
      helpCenterContent: [],
      devicesHelpContent: [
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/RorelsesensorManualRev1.pdf', title: 'Rörelse sensor', image: 'SensorMotion' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/SmartBrandvarnareManualRev1.pdf', title: 'Brandvarnare', image: 'SensorSmoke' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/DorrFonsterSensor.pdf', title: 'Dörr & fönstersensor', image: 'SensorDoorWindow' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/SmartPlugManual.pdf', title: 'Smart Plug', image: 'SmartPlug' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/SmartSirenManual.pdf', title: 'Smart siren', image: 'IASWarning' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/TemperaturFuktsensorManual.pdf', title: 'Temperatur-/fuktsensor', image: 'SensorTemperatureIndoor' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/VattenlackageSensorManual.pdf', title: 'Vattenläckage Sensor', image: 'SensorWaterLeak' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/VibrationssensorManual.pdf', title: 'Vibrationssensor', image: 'SensorMotion' },
        { link: 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/HomevisionGatewayManualRev3.pdf', title: 'Homevision Gateway', image: 'gateway' }
      ],
      selectedArticle: []
    }
  },
  computed: {
    helpContent () {
      return this.helpCenterContent.filter(item => {
        return item.Title.toLowerCase().includes(this.search.toLowerCase()) ||
               item.Description.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    platform () {
      return this.$store.state.platform
    }
  },
  watch: {
    $route: function () {
      if (this.$route.path === '/help-center') {
        this.getHelpCenterContent()
      }
    }
  },
  created () {
    this.getHelpCenterContent()
  },
  methods: {
    getHelpCenterContent () {
      this.loading = true
      this.$store.dispatch('getHelpCenterContent').then(response => {
        console.log(response.data)
        this.helpCenterContent = response.data
        this.loading = false
      })
      // this.$store.dispatch('getDevicesHelpContent').then(response => {
      //   this.devicesHelpContent = response.data
      //   this.loading = false
      // })
    },
    selectArticle (id) {
      const article = this.helpCenterContent.filter(item => item.id === id)
      this.selectedArticle = article
      console.log(this.selectedArticle)
      this.selectedView = '4'
    },
    openLink (link) {
      let linkToOpen = ''
      if (this.platform.includes('android')) {
        linkToOpen = 'https://docs.google.com/viewer?url=' + link
      } else {
        linkToOpen = link
      }
      InAppBrowser.create(linkToOpen).show()
    },
    // selectDeviceArticle (id) {
    //   const article = this.devicesHelpContent.filter(item => item.id === id)
    //   this.selectedArticle = article
    //   console.log(this.selectedArticle)
    //   this.selectedView = '3'
    // },
    mapDeviceImage (image) {
      if (image === 'sensorDoorWindow') {
        return 'SensorDoorWindow'
      } else if (image === 'sensorMotion') {
        return 'SensorMotion'
      } else if (image === 'sensorWaterLeak') {
        return 'SensorWaterLeak'
      } else if (image === 'smartLightColorDimmable') {
        return 'SmartLightColorDimmable'
      } else if (image === 'smartLightColorChangable') {
        return 'SmartLightColorChangable'
      } else if (image === 'sensorSmoke') {
        return 'SensorSmoke'
      } else if (image === 'wallSwitch') {
        return 'WallSwitch'
      } else if (image === 'smartPlug') {
        return 'SmartPlug'
      } else if (image === 'smartSiren') {
        return 'IASWarning'
      } else if (image === 'sensorTemperatureOutdoor') {
        return 'SensorTemperatureOutdoor'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
.h-55 {
   --min-height: 55px;
   margin-top: 8px;
   @include bg-box-item;
}
.search-bar {
  --box-shadow: none;
  color: white;
  --background: rgba(63, 66, 74, 0.8);
  border-radius: 5px;
}
ion-content, ion-item, ion-list, ion-header, ion-page {
  --background: transparent;
  background-color: transparent;
  color: white;
}
.welcome {
  color: white;
}
.add-button {
  max-width: 80px;
  max-height: 30px;
}
.connect-devices{
  padding:0 8px;
  .device-img {
    margin: 20px auto;
    max-width: 80px;
  }
  .device{
    height: 180px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
    @include bg-box-room-device;
  h3{
    margin: 0;
    font-size: 13px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
  }
  .inactive {
    @include bg-box-room-device-inactive;
  }
  .room-card-devices-item-inactive {
  border: 1px solid red;
}
}
.white-bg {
  background-color: white;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}
</style>